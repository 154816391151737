$(document).on('turbolinks:load', function () {

  if ($(window).width() >= 768) {
    $("#computer-cart").sticky({topSpacing: 150});
  }

  var windowHeight = $(window).height() - 84;
  var footerBottomAdjust = $('#footer').height() + 40;
  $('#mobile-cart-button').sticky({
    topSpacing: windowHeight,
    bottomSpacing: footerBottomAdjust
  });

  $('#mobileCartModal').on('shown.bs.modal', function (e) {
    var notification = $("#cart-notification").length;

    if (notification) {
      $("#cart-notification").remove()
    }
  })
});
