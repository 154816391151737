$(document).on('turbolinks:load', function () {

  $(window).scroll(function () {
    var position = $(this).scrollTop();
    if (position >= 100) {
      $('.logo').addClass('logo-on-scroll');
      $('.navbar').removeClass('custom-navbar');
    } else {
      $('.logo').removeClass('logo-on-scroll');
      $('.navbar').addClass('custom-navbar');
    }
  });

});
