$(document).on('turbolinks:load', function () {

  var new_order = $("#new_order").length;

  if (new_order) {
    $('html, body').animate({
      scrollTop: $('#new_order').offset().top - 100
    }, 'slow');
  }

});
