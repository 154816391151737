$(document).on('turbolinks:load', function () {

  $('#mobile-scrolling-table').delay(600).animate({
    scrollLeft: 100
  }, 800, function () {
    $('#mobile-scrolling-table').animate({
      scrollLeft: 0
    }, 500);
  })

});
